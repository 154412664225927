import "./Skills.css"
import React from 'react'
import skills from "../../../Assets/skills.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ai from '../../../Assets/carousel/ai.png'
import bl from '../../../Assets/carousel/bl.png'
import cor from '../../../Assets/carousel/cor.png'
import fig from '../../../Assets/carousel/fig.png'
import id from '../../../Assets/carousel/id.png'
import pr from '../../../Assets/carousel/pr.png'
import ps from '../../../Assets/carousel/ps.png'
import sk from '../../../Assets/carousel/sk.png'
import xd from '../../../Assets/carousel/xd.png'

export default function Skills() {
  const images = [ai, bl, cor, fig, id, pr, ps, sk, xd,];
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 1500,
    slidesToShow: 2,
    slidesToScroll: 1
  };
  return (
    <div className='container skills'>
      <div className="content-container">
        <div className="row text-end align-items-end">
          <h6>My Skills</h6>
          <h5>Unleashing the Power of Design</h5>
          <p>Explore my Skills Showcase – a curated collection where creativity, technical finesse, and strategic thinking converge. From UX mastery to visual design finesse, witness the skills that drive my commitment to crafting seamless and impactful digital experiences.</p>
          <div className="">

            <button className="btn primary">Download Resume</button>
          </div>
        </div>
      </div>
      <div className="skills-img">
        <img src={skills} alt="" />
      </div>
      <div className="mob-carousel mb-4">
        <Slider {...settings}>
          {images.map(src => <img src={src} className={'d-block '}
            alt={'image'} />)}
        </Slider>

      </div>
    </div>
  )
}
