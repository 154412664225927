import React from 'react'
import "./Banner.css"
import logo from "../../../Assets/Logo.png";
import bannerImage from "../../../Assets/banner.png";
import bannerElement from "../../../Assets/banner_element.png";

import { useState } from "react"
import { RiMenu3Line } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
export default function Banner() {
    const [menu, setMenu] = useState(false)
  
    return (
        <>
            <div className='banner'>
                <div className="header">
                    <div className="header-inner container">
                        <div className="logo">
                            <img src={logo} alt="" />
                        </div>
                            {/* <div className="menu">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="25" viewBox="0 0 32 25" fill="none">
                                    <path d="M1 23.8571H31ZM1 12.4286H31ZM1 1H31Z" fill="#EEEEEE" />
                                    <path d="M1 23.8571H31M1 12.4286H31M1 1H31" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div> */}
                    </div>
                </div>
                <div className="main">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 d-flex align-items-center">
                                <div className="content-box">

                                    <h3>Hello,</h3>
                                    <h3>I'm <span>Asmita Singh</span></h3>
                                    <h4>PRODUCT DESIGNER <span className='seprator'><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                        <circle opacity="0.75" cx="5" cy="5" r="5" fill="#EC8F5E" />
                                    </svg></span> UX & UI DESIGNER</h4>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="banner-image-container">

                                    <div className="banner-image">

                                        <img src={bannerImage} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="banner_element">
                    <img src={bannerElement} alt="" />
                </div>

            </div>
            <div className="nav">
                <div className="menu">
                    {
                        !menu ?
                            <RiMenu3Line color="#fff" size={30} onClick={() => {
                                setMenu(!menu)
                            }} /> :
                            <RxCross2 color="#fff" size={30} onClick={() => {
                                setMenu(!menu)
                            }} />
                    }

                </div>
                <div className={menu ? "navigation open" : "navigation "}>
                    {/* <button style={{"--i":0}}  className="navigator active">Home</button> */}
                    <button style={{ "--i": 1 }} className="navigator"> Resume</button>
                    <button style={{ "--i": 2 }} className="navigator">Projects</button>
                    <button style={{ "--i": 2 }} className="navigator">Behance</button>
                    <button style={{ "--i": 2 }} className="navigator">Contact</button>
                    <button style={{ "--i": 2 }} className="menu-indicator navigator">MENU</button>

                </div>
            </div>
        </>

    )
}
