import React from 'react'
import Banner from './Components/Banner'
import Philosohy from './Components/Philosohy'
import Skills from './Components/Skills.js'
import Contact from './Components/Contact.js'
import Services from './Components/Services.js'

export default function Home() {
  return (
    <div>
        <Banner/>
        <Philosohy/>
        <Skills/>
        <Services/>
        <Contact/>
    </div>
  )
}
