import React from 'react'
import "./Services.css"
import logo from "../../../Assets/services/Logo.png"
import mobile from "../../../Assets/services/mobile.png"
import website from "../../../Assets/services/website.png"
import poster from "../../../Assets/services/poster.png"
import carousel from "../../../Assets/services/carousel.png"
import book from "../../../Assets/services/book.png"
import Slider from 'react-slick'

export default function Services() {
    const allServices = [
        {
            img: logo,
            head: () => <>LOGO <br /> DESIGN</>,
            p: " The Adobe Express logo creator lets you instantly generate quality logos"
        },
        {
            img: website ,
            head: () => <>WEBSITE <br /> DESIGN</>,
            p: " The Adobe Express logo creator lets you instantly generate quality logos"
        },
        {
            img: mobile,
            head: () => <>MOBILE APP  <br /> DESIGN</>,
            p: " The Adobe Express logo creator lets you instantly generate quality logos"
        },
        {
            img: poster,
            head: () => <>POSTER <br /> DESIGN</>,
            p: " The Adobe Express logo creator lets you instantly generate quality logos"
        },
        {
            img: carousel,
            head: () => <>CAROUSEL <br /> DESIGN</>,
            p: " The Adobe Express logo creator lets you instantly generate quality logos"
        },
        {
            img: book,
            head: () => <>BOOK COVER <br /> DESIGN</>,
            p: " The Adobe Express logo creator lets you instantly generate quality logos"
        },
    ]
    const settings = {
        dots: false,
        infinite: true,
        autoplay:true,
        arrows: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: false,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };
    return (
        <div className='container services'>
            <div className="content-container">
                <div className="row text-start align-items-end">
                    <h6>My Services</h6>
                    <h5>Where your vision meets my creativity</h5>
                    {/* <p>Explore my Skills Showcase – a curated collection where creativity, technical finesse, and strategic thinking converge. From UX mastery to visual design finesse, witness the skills that drive my commitment to crafting seamless and impactful digital experiences.</p> */}
                    <div className="">

                    </div>
                </div>
            </div>
            <Slider {...settings}>
                {allServices.map(service => {
                    return <div>

                        <div className='s-card'>
                            <div className="icon">
                                <div className="icon-inner">
                                    <img src={service.img} alt="" />
                                </div>
                            </div>
                            <h3>
                                {service.head()}
                            </h3>
                            <p>
                                {service.p}
                            </p>
                        </div>
                    </div>
                })}
            </Slider>

            <div className="  text-center mt-5 mb-5">

                <button className=" enquire btn primary">Enquire now</button>
            </div>
        </div>
    )
}
