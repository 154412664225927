import React from 'react'
import logo from "../../../Assets/Logo.png";
import "./Contact.css";

export default function Contact() {
  return (
    <div className="container contact-us text-center">
      <div className="col-md-6 br-1 content-side">
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <h3>Asmita Singh</h3>
        <p>Explore my Skills Showcase – a curated collection where creativity, technical finesse, and strategic thinking converge.</p>
        <div className="links d-flex g-3">
          <div className="behance">
            <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.448 12.8309H18.44V11.8357H22.4496V12.8325L22.448 12.8309ZM15.7712 16.9157C16.0304 17.3156 16.1584 17.8021 16.1584 18.3733C16.1584 18.9653 16.0144 19.4932 15.7168 19.9621C15.5186 20.2914 15.2536 20.5755 14.9389 20.7963C14.6242 21.017 14.2667 21.1694 13.8896 21.2436C13.4266 21.3363 12.9554 21.3813 12.4832 21.3781H8V11.4229H12.808C14.0192 11.4421 14.8784 11.7941 15.3856 12.4837C15.6896 12.9061 15.8416 13.4133 15.8416 14.0037C15.8416 14.6117 15.6896 15.0997 15.3808 15.4693C15.2112 15.6773 14.9584 15.8661 14.624 16.0357C15.1296 16.2213 15.5136 16.5125 15.7712 16.9157ZM10.296 15.3477H12.4032C12.8352 15.3477 13.1856 15.2661 13.4544 15.1013C13.7248 14.9365 13.8592 14.6453 13.8592 14.2261C13.8592 13.7621 13.6816 13.4549 13.3248 13.3077C12.9436 13.1935 12.5466 13.1406 12.1488 13.1509H10.296V15.3477ZM14.0624 18.2309C14.0624 17.7141 13.8496 17.3573 13.4288 17.1653C13.192 17.0565 12.8592 17.0005 12.4336 16.9957H10.296V19.6484H12.4C12.832 19.6484 13.168 19.5925 13.408 19.474C13.8432 19.258 14.0624 18.8453 14.0624 18.2309ZM23.936 16.6117C23.984 16.9381 24.0064 17.4101 23.9984 18.0277H18.8064C18.8352 18.7445 19.0816 19.2453 19.552 19.5317C19.8336 19.7125 20.176 19.8005 20.5792 19.8005C21.0032 19.8005 21.3472 19.6933 21.6144 19.4725C21.7735 19.3366 21.9036 19.1701 21.9968 18.9829H23.9008C23.8496 19.4069 23.6208 19.8357 23.2096 20.2725C22.5696 20.9653 21.6768 21.3124 20.5264 21.3124C19.6121 21.3243 18.7228 21.0139 18.0144 20.4357C17.2912 19.8501 16.9264 18.8996 16.9264 17.5796C16.9264 16.3429 17.2544 15.3957 17.9088 14.7365C18.5648 14.0757 19.4144 13.7477 20.4608 13.7477C21.08 13.7477 21.6384 13.8581 22.1376 14.0805C22.6336 14.3045 23.0448 14.6549 23.368 15.1365C23.6608 15.5621 23.848 16.0517 23.936 16.6117ZM22.064 16.7973C22.0288 16.3013 21.8624 15.9269 21.5648 15.6709C21.2576 15.4092 20.8641 15.2712 20.4608 15.2837C19.9808 15.2837 19.6112 15.4229 19.3472 15.6933C19.0832 15.9653 18.9184 16.3333 18.8512 16.7973H22.064Z" fill="white" />
              <path d="M6.4 3.42266C5.55131 3.42266 4.73737 3.7598 4.13726 4.35991C3.53714 4.96003 3.2 5.77396 3.2 6.62266V25.8227C3.2 26.6713 3.53714 27.4853 4.13726 28.0854C4.73737 28.6855 5.55131 29.0227 6.4 29.0227H25.6C26.4487 29.0227 27.2626 28.6855 27.8627 28.0854C28.4629 27.4853 28.8 26.6713 28.8 25.8227V6.62266C28.8 5.77396 28.4629 4.96003 27.8627 4.35991C27.2626 3.7598 26.4487 3.42266 25.6 3.42266H6.4ZM6.4 0.222656H25.6C27.2974 0.222656 28.9252 0.89694 30.1255 2.09717C31.3257 3.29741 32 4.92527 32 6.62266V25.8227C32 27.52 31.3257 29.1479 30.1255 30.3481C28.9252 31.5484 27.2974 32.2227 25.6 32.2227H6.4C4.70261 32.2227 3.07475 31.5484 1.87452 30.3481C0.674284 29.1479 0 27.52 0 25.8227V6.62266C0 4.92527 0.674284 3.29741 1.87452 2.09717C3.07475 0.89694 4.70261 0.222656 6.4 0.222656Z" fill="white" />
            </svg>
          </div>
          <div className="mail">
            <svg width="40" height="33" viewBox="0 0 40 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M40 4.22266C40 2.02266 38.2 0.222656 36 0.222656H4C1.8 0.222656 0 2.02266 0 4.22266V28.2227C0 30.4227 1.8 32.2227 4 32.2227H36C38.2 32.2227 40 30.4227 40 28.2227V4.22266ZM36 4.22266L20 14.2227L4 4.22266H36ZM36 28.2227H4V8.22266L20 18.2227L36 8.22266V28.2227Z" fill="white" />
            </svg>

          </div>
          <div className="resume">
            <svg width="33" height="36" viewBox="0 0 33 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 21.4227V15.0227C2 8.98906 2 5.97146 3.8752 4.09786C5.7488 2.22266 8.7664 2.22266 14.8 2.22266H18C24.0336 2.22266 27.0512 2.22266 28.9248 4.09786C29.9712 5.14266 30.4336 6.54266 30.6368 8.62266M30.8 15.0227V21.4227C30.8 27.4563 30.8 30.4739 28.9248 32.3475C27.0512 34.2227 24.0336 34.2227 18 34.2227H14.8C8.7664 34.2227 5.7488 34.2227 3.8752 32.3475C2.8288 31.3027 2.3664 29.9027 2.1632 27.8227M10 21.4227H18M10 15.0227H11.6M22.8 15.0227H16.4" stroke="white" stroke-width="3" stroke-linecap="round" />
            </svg>

          </div>

        </div>

      </div>
      <div className="col-md-6">
        <div className="form-container">
          <h3>Reach out to me</h3>


          <div className=" container contact-form ">
            <div className="form-group">

              <label htmlFor="">Name</label>
              <input type="text" className="form-input" />
            </div>
            <div className="form-group">
              <label htmlFor="">Phone No.</label>
              <div className="phone-input">
                <span className="begin">+91</span>
                <input type="text" className="form-input" />
              </div>
            </div>
            <div className="form-group">

              <label htmlFor="">Message</label>
              <textarea className="form-textarea" > </textarea>
            </div>
            <div className="  text-center mt-5 mb-5">

              <button className=" enquire btn primary">Contact Me</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
